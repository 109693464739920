import React from "react";
import { useRouterContext, TitleProps, useGetIdentity } from "@pankod/refine-core";
import { Heading, Link as ChakraLink } from "@chakra-ui/react";
import styles from './styles.module.css'

interface IUser {
  id: string
  name: string
  email: string
  role: string
  company: {
    id: string
    cnpj: string
    name: string
  }
}


export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  const { Link } = useRouterContext();

  const { data: identity } = useGetIdentity<IUser>();

  return (
    <ChakraLink as={Link} to="/">
      {collapsed ? (
        <Heading className={styles.title}>{identity?.company.name}</Heading>
      ) : (
        <Heading className={styles.title}>{identity?.company.name}</Heading>
      )}
    </ChakraLink>
  );
};
