import { useShow } from "@pankod/refine-core";
import {
    Show,
    Heading,
    TextField,
} from "@pankod/refine-chakra-ui";

export const WarehouseShow = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Heading as="h5" size="sm" mt={4}>
                Id
            </Heading>
            <TextField value={record?.id} />
            <Heading as="h5" size="sm" mt={4}>
                Galpão
            </Heading>
            <TextField value={record?.name} />
            <Heading as="h5" size="sm" mt={4}>
                Endereço
            </Heading>
            <TextField value={record?.address} />
            <Heading as="h5" size="sm" mt={4}>
                Cidade
            </Heading>
            <TextField value={record?.city} />
        </Show>
    );
};