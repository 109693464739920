import {
  Create,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
} from "@pankod/refine-chakra-ui";
import { useSelect } from "@pankod/refine-core";
import { useForm } from "@pankod/refine-react-hook-form";

export const ProductCreate = () => {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    formState: { errors },
  } = useForm();

  const { options: categoryOptions } = useSelect({
    optionLabel: "name",
    optionValue: "id",
    resource: "categories",
  });

  const { options: subcategoryOptions } = useSelect({
    optionLabel: "name",
    optionValue: "id",
    resource: "subcategories",
  });

  const { options: measureOptions } = useSelect({
    optionLabel: "name",
    optionValue: "id",
    resource: "measures",
  });


  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <FormControl mb="3" isInvalid={!!(errors as any)?.name}>
        <FormLabel>Nome</FormLabel>
        <Input
          type="text"
          {...register("name", {
            required: "Campo obrigatório",
          })}
        />
        <FormErrorMessage>
          {(errors as any)?.name?.message as string}
        </FormErrorMessage>
      </FormControl>
      <FormControl mb="3" isInvalid={!!(errors as any)?.description}>
        <FormLabel>Descrição</FormLabel>
        <Input
          type="text"
          {...register("description", {
            required: "Campo obrigatório",
          })}
        />
        <FormErrorMessage>
          {(errors as any)?.description?.message as string}
        </FormErrorMessage>
      </FormControl>
      <FormControl mb="3" isInvalid={!!(errors as any)?.category?.name}>
        <FormLabel>Categoria</FormLabel>
        <Select
          placeholder="Selecione uma categoria"
          {...register("category.id", {
            required: "Campo obrigatório",
          })}
        >
          {categoryOptions?.map((option) => {
            return (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            )
          })}
        </Select>
        <FormErrorMessage>
          {(errors as any)?.category?.name?.message as string}
        </FormErrorMessage>
      </FormControl>
      <FormControl mb="3" isInvalid={!!(errors as any)?.subcategory?.name}>
        <FormLabel>Subcategoria</FormLabel>
        <Select
          placeholder="Selecione uma subcategoria"
          {...register("subcategory.id", {
            required: "Campo obrigatório",
          })}
        >
          {subcategoryOptions?.map((option) => {
            return (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            )
          })}
        </Select>
        <FormErrorMessage>
          {(errors as any)?.subcategory?.name?.message as string}
        </FormErrorMessage>
      </FormControl>
      <FormControl mb="3" isInvalid={!!(errors as any)?.measure?.name}>
        <FormLabel>Unidade de medida</FormLabel>
        <Select
          placeholder="Selecione uma unidade de medida"
          {...register("measure.id", {
            required: "Campo obrigatório",
          })}
        >
          {measureOptions?.map((option) => {
            return (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            )
          })}
        </Select>
        <FormErrorMessage>
          {(errors as any)?.measure?.name?.message as string}
        </FormErrorMessage>
      </FormControl>
    </Create>
  );
};