import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  ChakraProvider,
  refineTheme,
  ErrorComponent,
  Layout,
} from "@pankod/refine-chakra-ui";
import { dataProvider } from './rest-data-provider'
import { useTranslation } from "react-i18next";
import { axiosInstance } from "./rest-data-provider/utils";

import routerProvider from "@pankod/refine-react-router-v6";
import { authProvider } from "./authProvider";
import { AuthPage } from "./components/pages/auth";
import { ProductList } from "components/products/list";
import { ProductEdit } from "components/products/edit";
import { ProductShow } from "components/products/show";
import { ProductCreate } from "components/products/create";
import { CategoryList } from "components/categories/list";
import { CategoryShow } from "components/categories/show";
import { CategoryCreate } from "components/categories/create";
import { CategoryEdit } from "components/categories/edit";
import { WarehouseList } from "components/warehouses/list";
import { WarehouseShow } from "components/warehouses/show";
import { WarehouseCreate } from "components/warehouses/create";
import { WarehouseEdit } from "components/warehouses/edit";
import { Title } from "components/atoms/Title";
import { SubcategoryList } from "components/subcategories/list";
import { SubcategoryShow } from "components/subcategories/show";
import { SubcategoryCreate } from "components/subcategories/create";
import { SubcategoryEdit } from "components/subcategories/edit";
import { MeasureList } from "components/measures/list";
import { MeasureShow } from "components/measures/show";
import { MeasureCreate } from "components/measures/create";
import { MeasureEdit } from "components/measures/edit";

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001";

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, options?: any) => t(key, options),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <ChakraProvider theme={refineTheme}>
      <Refine
        notificationProvider={notificationProvider()}
        Title={Title}
        catchAll={<ErrorComponent />}
        Layout={Layout}
        routerProvider={routerProvider}
        dataProvider={dataProvider(apiUrl, axiosInstance)}
        authProvider={authProvider(apiUrl, axiosInstance)}
        i18nProvider={i18nProvider}
        resources={[
          {
            name: "products",
            list: ProductList,
            show: ProductShow,
            create: ProductCreate,
            edit: ProductEdit,
          },
          {
            name: "categories",
            canDelete: true,
            list: CategoryList,
            show: CategoryShow,
            create: CategoryCreate,
            edit: CategoryEdit,
          },
          {
            name: "subcategories",
            canDelete: true,
            list: SubcategoryList,
            show: SubcategoryShow,
            create: SubcategoryCreate,
            edit: SubcategoryEdit,
          },
          {
            name: "measures",
            canDelete: true,
            list: MeasureList,
            show: MeasureShow,
            create: MeasureCreate,
            edit: MeasureEdit,
          },
          {
            name: "warehouses",
            canDelete: true,
            list: WarehouseList,
            show: WarehouseShow,
            create: WarehouseCreate,
            edit: WarehouseEdit,
          }
        ]}
        LoginPage={AuthPage}
      />
    </ChakraProvider>
  );
}

export default App;
