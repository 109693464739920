import {
  Create,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
} from "@pankod/refine-chakra-ui";
import { useForm } from "@pankod/refine-react-hook-form";

export const WarehouseCreate = () => {
  const {
      refineCore: { formLoading },
      saveButtonProps,
      register,
      formState: { errors },
  } = useForm();

  return (
      <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
          <FormControl mb="3" isInvalid={!!(errors as any)?.name}>
              <FormLabel>Galpão</FormLabel>
              <Input
                  type="text"
                  {...register("name", {
                      required: "Campo obrigatório",
                  })}
              />
              <FormErrorMessage>
                  {(errors as any)?.name?.message as string}
              </FormErrorMessage>
          </FormControl>
          <FormControl mb="3" isInvalid={!!(errors as any)?.address}>
              <FormLabel>Endereço</FormLabel>
              <Input
                  type="text"
                  {...register("address", {
                      required: "Campo obrigatório",
                  })}
              />
              <FormErrorMessage>
                  {(errors as any)?.address?.message as string}
              </FormErrorMessage>
          </FormControl>
          <FormControl mb="3" isInvalid={!!(errors as any)?.city}>
              <FormLabel>Cidade</FormLabel>
              <Input
                  type="text"
                  {...register("city", {
                      required: "Campo obrigatório",
                  })}
              />
              <FormErrorMessage>
                  {(errors as any)?.city?.message as string}
              </FormErrorMessage>
          </FormControl>
      </Create>
  );
};