import { useShow } from "@pankod/refine-core";
import {
    Show,
    Heading,
    NumberField,
    TextField,
} from "@pankod/refine-chakra-ui";

export const ProductShow = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Heading as="h5" size="sm" mt={4}>
                Id
            </Heading>
            <NumberField value={record?.id ?? ""} />
            <Heading as="h5" size="sm" mt={4}>
                Nome
            </Heading>
            <TextField value={record?.name} />
            <Heading as="h5" size="sm" mt={4}>
                Descrição
            </Heading>
            <TextField value={record?.description} />
            <Heading as="h5" size="sm" mt={4}>
                Categoria
            </Heading>
            <TextField value={record?.category?.name} />
        </Show>
    );
};