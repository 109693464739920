import { HttpError } from "@pankod/refine-core";
import { AxiosInstance } from "axios";

export const authProvider = (apiUrl: string, axiosInstance: AxiosInstance) => {
  const login = async ({ email, password }: { email: string, password: string }) => {
    const { data } = await axiosInstance.post(`${apiUrl}/auth/signin`, {
      email,
      password,
    });

    if (data) {
      localStorage.setItem("token", data.token);
      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${data.token}`,
      }
      return Promise.resolve();
    }

    return Promise.reject({
      name: "Seu login falhou!",
      message: "O e-mail ou senha não corresponde a nenhuma conta.",
    });
  }

  const logout = async () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  }

  const checkAuth = async () => {
    const token = localStorage.getItem("token");

    if (token) {
      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
      }
    }

    const { data } = await axiosInstance.get(`${apiUrl}/auth/me`);

    if (data) {
      return Promise.resolve(data);
    }

    return Promise.reject();
  }

  const checkError = async (error: HttpError) => {
    // const status = error.status;
    // if (status === 401 || status === 403) {
    //     localStorage.removeItem("token");
    //     return Promise.reject();
    // }
    // return Promise.resolve();
    return Promise.resolve();
  }

  const getUserIdentity = async () => {
    const data = await checkAuth();
    return Promise.resolve(data)
  }

  return {
    login,
    logout,
    checkAuth,
    checkError,
    getUserIdentity
  }
}