import { UseFormProps } from "@pankod/refine-react-hook-form";
import React from "react";
import { AuthPageProps } from "@pankod/refine-core";
import { BoxProps } from "@pankod/refine-chakra-ui";
import {
  LoginPage,
} from "./components";

export interface FormPropsType<TFormType> extends UseFormProps {
  onSubmit?: (values: TFormType) => void;
}

export type AuthProps = AuthPageProps<BoxProps, BoxProps, FormPropsType<any>>;

/**
 * **refine** has a default auth page form which is served on `/login` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/api-references/components/refine-config#authpage} for more details.
 */
export const AuthPage: React.FC<AuthProps> = (props) => {
  const { type } = props;
  const renderView = () => {
    switch (type) {
      default:
        return <LoginPage {...props} />;
    }
  };

  return <>{renderView()}</>;
};
