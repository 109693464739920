import React from "react";
import {
    Edit,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
} from "@pankod/refine-chakra-ui";
import { useForm } from "@pankod/refine-react-hook-form";

export const WarehouseEdit = () => {
    const {
        refineCore: { formLoading, queryResult },
        saveButtonProps,
        register,
        resetField,
        formState: { errors },
    } = useForm();

    const warehousesData = queryResult?.data?.data;

    return (
        <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
            <FormControl mb="3" isInvalid={!!(errors as any)?.id}>
                <FormLabel>Código</FormLabel>
                <Input
                    disabled
                    type="text"
                    {...register("id", {
                        required: "Campo obrigatório",
                    })}
                />
                <FormErrorMessage>
                    {(errors as any)?.id?.message as string}
                </FormErrorMessage>
            </FormControl>
            <FormControl mb="3" isInvalid={!!(errors as any)?.name}>
                <FormLabel>Galpão</FormLabel>
                <Input
                    type="text"
                    {...register("name", {
                        required: "Campo obrigatório",
                    })}
                />
                <FormErrorMessage>
                    {(errors as any)?.name?.message as string}
                </FormErrorMessage>
            </FormControl>
            <FormControl mb="3" isInvalid={!!(errors as any)?.address}>
                <FormLabel>Endereço</FormLabel>
                <Input
                    type="text"
                    {...register("address", {
                        required: "Campo obrigatório",
                    })}
                />
                <FormErrorMessage>
                    {(errors as any)?.address?.message as string}
                </FormErrorMessage>
            </FormControl>
            <FormControl mb="3" isInvalid={!!(errors as any)?.city}>
                <FormLabel>Cidade</FormLabel>
                <Input
                    type="text"
                    {...register("city", {
                        required: "Campo obrigatório",
                    })}
                />
                <FormErrorMessage>
                    {(errors as any)?.city?.message as string}
                </FormErrorMessage>
            </FormControl>
        </Edit>
    );
};