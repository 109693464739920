import React from "react";
import {
  Edit,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
  SaveButton,
} from "@pankod/refine-chakra-ui";
import { useForm } from "@pankod/refine-react-hook-form";
import { useSelect } from "@pankod/refine-core";

export const ProductEdit = () => {
  const {
    refineCore: { formLoading, queryResult },
    saveButtonProps,
    register,
    formState: { errors },
  } = useForm();

  const productsData = queryResult?.data?.data;

  const { options: categoryOptions } = useSelect({
    optionLabel: "name",
    resource: "categories",
    defaultValue: productsData?.category?.id,
    optionValue: "id",
  });

  return (
    <Edit isLoading={formLoading} footerButtons={<SaveButton {...saveButtonProps}>Salvar</SaveButton>} >
      <FormControl mb="3" isInvalid={!!(errors as any)?.id}>
        <FormLabel>Código</FormLabel>
        <Input
          disabled
          type="number"
          {...register("id", {
            required: "This field is required",
            valueAsNumber: true,
          })}
        />
        <FormErrorMessage>
          {(errors as any)?.id?.message as string}
        </FormErrorMessage>
      </FormControl>
      <FormControl mb="3" isInvalid={!!(errors as any)?.name}>
        <FormLabel>Produto</FormLabel>
        <Input
          type="text"
          {...register("name", {
            required: "Campo obrigatório",
          })}
        />
        <FormErrorMessage>
          {(errors as any)?.name?.message as string}
        </FormErrorMessage>
      </FormControl>
      <FormControl mb="3" isInvalid={!!(errors as any)?.description}>
        <FormLabel>Descrição</FormLabel>
        <Input
          type="text"
          {...register("description", {
            required: "Campo obrigatório",
          })}
        />
        <FormErrorMessage>
          {(errors as any)?.description?.message as string}
        </FormErrorMessage>
      </FormControl>
      <FormControl mb="3" isInvalid={!!(errors as any)?.category?.name}>
        <FormLabel>Categoria</FormLabel>
        <Select
          placeholder="Selecione uma categoria"
          {...register("category.id", {
            required: "Campo obrigatório",
          })}
        >
          {categoryOptions?.map((option) => {
            return (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            )
          })}
        </Select>
        <FormErrorMessage>
          {(errors as any)?.category?.name?.message as string}
        </FormErrorMessage>
      </FormControl>
    </Edit>
  );
};